<script>
export default {
  data() {
    return {
      isOpen: false,
      showFor: "",
      title: "",
      description: "",
      link: "",
      isTitleError: false,
      isDescriptionError: false,
      isLinkError: false,
      roles: [
        {
          detalle: "Distribuidor - Dueño/Gerente/Director"
        },
        {
          detalle: "Distribuidor - Administrativo"
        },
        {
          detalle: "Distribuidor - Vendedor"
        },
        {
          detalle: "BASF"
        }
      ]
    };
  },
  mounted() {
    // Escuchar el evento para abrir el modal
    this.$eventHub.$on("open-create-new-ad-modal", shouldOpen => {
      this.isOpen = shouldOpen;
    });
    this.$eventHub.$on("anuncio-editar", anuncio => this.editar(anuncio));
  },
  updated() {
    if (this.isOpen) {
      document.body.classList.add("body-no-scroll");
    }
  },
  methods: {
    editar(anuncio) {
      this.title = anuncio.titulo;
      this.description = anuncio.descripcion;
      this.link = anuncio.link;
      this.idElegido = anuncio.id;
      this.showFor = anuncio.rol;
      // this.$refs.roles.selectedOption = this.roles.filter(
      //   r => r.detalle == anuncio.rol
      // )[0];
      // this.$refs.roles.$refs.dropdownInput.value = anuncio.rol;
      this.isOpen = true;
    },
    cerrar() {
      this.isOpen = false;
      this.title = "";
      this.description = "";
      this.link = "";
      this.isTitleError = false;
      this.isDescriptionError = false;
      this.isLinkError = false;
      this.idElegido = null;
      this.showFor = null;
      // Elimina la clase para habilitar nuevamente el scroll
      document.body.classList.remove("body-no-scroll");
      // this.$refs.roles.selectedOption = null;
      // this.$refs.roles.$refs.dropdownInput.value = "";
    },
    guardar() {
      let continuar = true;

      if (this.title == "") {
        this.isTitleError = true;
        continuar = false;
      } else {
        this.isTitleError = false;
      }

      if (this.description == "") {
        this.isDescriptionError = true;
        continuar = false;
      } else {
        this.isDescriptionError = false;
      }

      if (this.link == "") {
        this.isLinkError = true;
        continuar = false;
      } else {
        this.isLinkError = false;
      }

      if (continuar) {
        const json = {
          id: this.idElegido,
          titulo: this.title,
          descripcion: this.description,
          link: this.link,
          rol: this.showFor
        };
        const that = this;
        this.$axios
          .put(this.$localurl + "/api/anuncio", json)
          .then(function() {
            that.$eventHub.$emit("anuncio-nuevo");
          })
          .catch(function(response) {
            console.log(response);
          });
        this.cerrar();
      }
    }
  }
};
</script>
<template>
  <transition name="fade-fast">
    <div v-if="isOpen">
      <div class="create-new-ad-modal__background" @click="cerrar"></div>
      <div class="create-new-ad-modal__container">
        <div class="create-new-ad-modal__header">
          <p class="create-new-ad-modal__title">Crear un nuevo anuncio</p>
          <img
            class="create-new-ad-modal__close-icon"
            src="../../assets/img/landing/close.svg"
            alt="close"
            @click="cerrar"
          />
        </div>
        <label class="create-new-ad-modal__title" for="showFor"
          >Mostrar para</label
        >
        <div class="create-new-ad-modal__select__container">
          <img
            class="create-new-ad-modal__eye-icon"
            src="../../assets/img/icons/eye.svg"
            alt="ojo"
          />
          <select
            class="create-new-ad-modal__input create-new-ad-modal__select"
            id="showFor"
            v-model="showFor"
          >
            <option value="">Todos</option>
            <option
              v-for="item in roles"
              :value="item.detalle"
              :key="item.detalle"
              >{{ item.detalle }}</option
            >
          </select>
          <img
            class="create-new-ad-modal__arrow-down-select"
            src="../../assets/img/icons/arrow-down-select.svg"
            alt="ojo"
          />
        </div>

        <label class="create-new-ad-modal__title" for="title"
          >Título del anuncio</label
        >
        <input
          class="create-new-ad-modal__input"
          :class="{ 'error-input': isTitleError }"
          id="title"
          type="text"
          placeholder="Escribe el nombre"
          v-model="title"
        />

        <label class="create-new-ad-modal__title" for="description"
          >Descripción</label
        >
        <textarea
          class="create-new-ad-modal__input create-new-ad-modal__text-area"
          :class="{ 'error-input': isDescriptionError }"
          id="description"
          name="description"
          rows="5"
          cols="33"
          v-model="description"
          placeholder="Escribe una breve descripción"
        >
        </textarea>

        <label class="create-new-ad-modal__title" for="link"
          >Link del enlace</label
        >
        <input
          class="create-new-ad-modal__input"
          :class="{ 'error-input': isLinkError }"
          id="link"
          type="text"
          placeholder="Pegar link de enlace acá"
          v-model="link"
        />

        <div class="create-new-ad-modal__footer">
          <button
            class="create-new-ad-modal__button create-new-ad-modal__button--cancel"
            @click="cerrar"
          >
            Cancelar
          </button>
          <button
            class="create-new-ad-modal__button create-new-ad-modal__button--publish"
            @click="guardar"
          >
            Publicar
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
<style src="../../assets/css/components/create-new-ad-modal.css"></style>
