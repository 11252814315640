<script>
export default {
  data() {
    return {
      isOpen: false,
      imagen: null,
      toolName: "",
      toolLink: "",
      nombreImagen: "",
      isSelectedFileError: false,
      isToolNameError: false,
      isToolLinkError: false,
      idElegido: null,
      dragFile: null
    };
  },
  mounted() {
    // Escuchar el evento para abrir el modal
    this.$eventHub.$on("open-add-new-tool-modal", shouldOpen => {
      this.isOpen = shouldOpen;
    });
    this.$eventHub.$on("herramienta-editar", herramienta =>
      this.editar(herramienta)
    );
  },
  updated() {
    if (this.isOpen) {
      document.body.classList.add("body-no-scroll");
    }
  },
  methods: {
    handleFileUpload() {
      const that = this;
      let reader = new FileReader();

      // Create a preview once image has loaded
      reader.onload = function() {
        that.reduceImageSize();
      };
      reader.readAsDataURL(this.$refs.imagenUpload.files[0]);
    },
    reduceImageSize() {
      // Read in file
      let file;
      if (this.dragFile) {
        file = this.dragFile;
      } else {
        file = this.$refs.imagenUpload.files[0];
      }
      this.nombreImagen = file.name;
      this.dragFile = null;
      const that = this;
      // Ensure it's an image
      if (file.type.match(/image.*/)) {
        console.log("An image has been loaded");

        // Load the image
        let reader = new FileReader();
        reader.onload = function(readerEvent) {
          let image = new Image();
          image.onload = function() {
            // Resize the image
            let canvas = document.createElement("canvas"),
              max_size = 200, // TODO : pull max size from a site config
              width = image.width,
              height = image.height;
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else {
              if (height > max_size) {
                width *= max_size / height;
                height = max_size;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(image, 0, 0, width, height);
            let dataUrl = canvas.toDataURL("image/jpeg");
            that.imagen = dataUrl.replace(
              /^data:image\/(png|jpg|jpeg);base64,/,
              ""
            );
          };
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    handleDragOver(event) {
      event.preventDefault();
    },
    handleDrop(event) {
      event.preventDefault();
      this.$refs.imagenUpload.files = event.dataTransfer.files;
      this.handleFileUpload();
    },

    clearSelection() {
      // Borra la selección de archivo
      this.imagen = null;
      // Resetea el input de archivo para permitir una nueva selección
      this.$refs.imagenUpload.value = "";
      this.dragFile = null;
    },
    cerrar() {
      this.isOpen = false;
      this.imagen = null;
      this.toolName = "";
      this.toolLink = "";
      this.isToolNameError = false;
      this.isToolLinkError = false;
      this.idElegido = null;
      this.dragFile = null;
      this.$eventHub.$emit("open-all-my-apps-modal", true);
      // Elimina la clase para habilitar nuevamente el scroll
      document.body.classList.remove("body-no-scroll");
    },
    editar(herramienta) {
      this.imagen = herramienta.imagen;
      this.nombreImagen = herramienta.imagenNombre;
      this.toolName = herramienta.nombre;
      this.toolLink = herramienta.link;
      this.idElegido = herramienta.id;
      this.isOpen = true;
    },
    guardar() {
      let continuar = true;

      if (this.toolName == "") {
        this.isToolNameError = true;
        continuar = false;
      } else {
        this.isToolNameError = false;
      }

      if (this.toolLink == "") {
        this.isToolLinkError = true;
        continuar = false;
      } else {
        this.isToolLinkError = false;
      }

      if (this.imagen == null) {
        this.isSelectedFileError = true;
        continuar = false;
      } else {
        this.isSelectedFileError = false;
      }

      if (continuar) {
        const json = {
          id: this.idElegido,
          imagen: this.imagen,
          imagenNombre: this.nombreImagen,
          nombre: this.toolName,
          link: this.toolLink
        };
        this.$eventHub.$emit("herramienta-nueva", json);
        this.cerrar();
        // const formData = new FormData();
        // formData.append("image", this.selectedFile);

        // const json = {
        //   id: this.idElegido,
        //   imagen: formData,
        //   imagenNombre: this.selectedFile.name,
        //   nombre: this.toolName,
        //   link: this.toolLink
        // };
        // this.$eventHub.$emit("herramienta-nueva", json);
        // this.cerrar();
      }
    }
  }
};
</script>
<template>
  <transition name="fade-fast">
    <div v-if="isOpen">
      <div class="add-new-tool-modal__background" @click="cerrar"></div>
      <div class="add-new-tool-modal__container">
        <div class="add-new-tool-modal__header">
          <p class="add-new-tool-modal__title">Agregar nueva herramienta</p>
          <img
            class="add-new-tool-modal__close-icon"
            src="../../assets/img/landing/close.svg"
            alt="close"
            @click="cerrar"
          />
        </div>
        <p class="add-new-tool-modal__title">Material de bajada</p>
        <div
          class="image-upload__container"
          :class="{ 'error-input': isSelectedFileError }"
          @dragover="handleDragOver"
          @drop="handleDrop"
          v-show="!Boolean(this.imagen)"
        >
          <label class="image-upload__label" for="file-upload">
            <img src="../../assets/img/icons/upload.svg" alt="subir" />
            <div>
              <p class="bold">Arrastra o selecciona archivo</p>
              <p>Recomendamos imagenes cuadradas</p>
            </div>
          </label>
          <input
            id="file-upload"
            type="file"
            accept="image/*"
            @change="handleFileUpload"
            ref="imagenUpload"
            style="display: none;"
          />
        </div>
        <div class="image-selected__container" v-show="Boolean(this.imagen)">
          <p class="image-selected__file-name">
            {{ imagen ? nombreImagen : "" }}
          </p>
          <img
            class="image-selected__delete-button"
            src="../../assets/img/icons/trash.svg"
            alt="eliminar"
            @click="clearSelection()"
          />
        </div>
        <label class="add-new-tool-modal__title" for="toolName"
          >Nombre de la herramienta</label
        >
        <input
          class="add-new-tool-modal__input"
          :class="{ 'error-input': isToolNameError }"
          id="toolName"
          type="text"
          placeholder="Escribe el nombre"
          v-model="toolName"
        />

        <label class="add-new-tool-modal__title" for="toolLink"
          >Link a la herramienta</label
        >
        <input
          class="add-new-tool-modal__input"
          :class="{ 'error-input': isToolLinkError }"
          id="toolLink"
          type="text"
          placeholder="Pegar link de enlace acá"
          v-model="toolLink"
        />
        <div class="add-new-tool-modal__footer">
          <button
            class="add-new-tool-modal__button add-new-tool-modal__button--cancel"
            @click="cerrar()"
          >
            Cancelar
          </button>
          <button
            class="add-new-tool-modal__button add-new-tool-modal__button--publish"
            @click="guardar()"
          >
            Publicar
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
<style src="../../assets/css/components/add-new-tool-modal.css"></style>
